import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RequestData, UserData } from './../../../../utils/typesData';
import * as S from './styles';
import CardListItem from '../../../../components/CardListItem';
import Loading from '../../../../components/Loading';
import db, { auth } from '../../../../config/firebaseDb';
import { formatDatetimeToString } from '../../../../utils/dateTime';

const ConsultRequestForm = () => {
	const [user, setUser] = useState<UserData>();
	const [loading, setLoading] = useState(true);
	const [listReq, setListReq] = useState<RequestData[]>([]);
	//const [listCli, setListCli] = useState<ClientData[]>([])
	//const [selectedCli, setSelectedCli] = useState(undefined)
	const navigate = useNavigate();

	useEffect(() => {
		console.log('[Request.List] init');

		const unsubscribe = auth.onAuthStateChanged(authUser => {
			if (authUser) {
				const { uid, email, displayName } = authUser;
				setUser({
					uid,
					Email: email || '',
					Nome: displayName || ''
				});
			}
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		let subscrib = function () {};
		try {
			setLoading(true);
			if (user == undefined || user == null) return;
			const uid = user.uid; // uid u78AscoUjSXxgh5ZjePEacusxfv1

			const q = query(collection(db, 'AppContato'), where('UidSolicitacao', '==', uid), orderBy('Protocolo', 'desc'));
			subscrib = onSnapshot(q, querySnapshot => {
				//console.log('Documento >> UPDATE LISTENER');
				const data: RequestData[] = [];
				querySnapshot.forEach(doc => {
					//console.log(doc.data());
					const oItem: RequestData = {
						id: doc.id,
						Assunto: doc.data().Assunto || '',
						ClienteId: doc.data().ClienteId || '',
						Cliente: doc.data().Cliente || '',
						ClienteLeu: doc.data().ClienteLeu || false,
						DthSolicitacao: doc.data().DthSolicitacao.toDate(),
						PrioridadeId: doc.data().PrioridadeId || 0,
						Protocolo: doc.data().Protocolo || '',
						Responsavel: doc.data().Responsavel || '',
						TipoServico: doc.data().TipoServico || 'Outros', //novo
						ServicoDes: doc.data().ServicoDes || '',
						StatusDes: doc.data().StatusDes || '',
						StatusId: doc.data().StatusId || '',
						Avaliacao: doc.data().Avaliacao || 0
					};
					data.push(oItem);
				});
				setListReq(data);
			});
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}

		return () => {
			subscrib();
			console.log('<<< [Request.List] Cancel Listner >>>');
		};
	}, [user]);

	//-----------------------------------//-----------------------------------

	return (
		<>
			<Loading open={loading} />
			<S.Container>
				{listReq.map(request => (
					<React.Fragment key={request.id}>
						<CardListItem
							title={request.TipoServico}
							description={request.Assunto}
							status={{ code: request.StatusId }}
							id={request.id}
							date={formatDatetimeToString(request.DthSolicitacao)}
							code={'Protocolo: ' + request.Protocolo}
							handleClick={function (): void {
								const serializedItem = JSON.stringify(request);
								navigate(`/request_item/${request.id}`, { state: { item: serializedItem } });
							}}
						/>
					</React.Fragment>
				))}
			</S.Container>
		</>
	);
};

export default ConsultRequestForm;
