import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as S from './styles';
import CardListItem, { type CardItemStatus } from '../../../../components/CardListItem';
import SelectCustom from '../../../../components/Globals/SelectCustom';
import db, { auth } from '../../../../config/firebaseDb';
import { ProcedureData } from '../../../../utils/typesData';
import { Box, Typography } from '@mui/material';
// import { SelectChangeEvent } from '@mui/material/Select';

interface dataPropsCli {
	id: string;
	label: string;
	name: string;
	astreaCod: string;
}

export const ProceduresForm: React.FC = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [user, setUser] = useState<any>(null);

	const [isLoading, setIsLoading] = useState(true);
	const [listProc, setListProc] = useState<ProcedureData[]>([]);
	const [listCli, setListCli] = useState<dataPropsCli[]>([]);
	const [selectedCli, setSelectedCli] = useState('');
	const [selectedCliId, setSelectedCliId] = useState('');

	const today = new Date().toISOString().split('T')[0];

	const finishStatus: CardItemStatus = {
		code: '99',
		statusDes: 'Finalizado'
	};

	const pendingStatus: CardItemStatus = {
		code: '1',
		statusDes: 'Pendente'
	};

	const fetchDataCli = async () => {
		if (user?.uid) {
			const q = query(
				collection(db, 'Cliente'),
				where('Usuario', 'array-contains', user?.uid),
				orderBy('NomeEmpresa', 'asc')
			);
			try {
				const querySnap = await getDocs(q);
				const dataCli: dataPropsCli[] = [];
				querySnap.forEach(doc => {
					const { NomeApelido, NomeEmpresa, AstreaCod } = doc.data();
					dataCli.push({
						id: doc.id,
						label: NomeApelido ? NomeApelido : NomeEmpresa,
						name: NomeApelido ? NomeApelido : NomeEmpresa,
						astreaCod: AstreaCod
					});
				});
				setListCli(dataCli);
				setSelectedCli(searchParams.get('cli') ?? '');
				setSelectedCliId(searchParams.get('id') ?? '');
			} catch (error) {
				console.error('[fetchDataCli] Err: ', error);
			}
		}
	};

	useEffect(() => {
		fetchDataCli();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		let subscrib = function () {};
		try {
			if (user == undefined || user == null) return;

			if (!selectedCli) return;

			let astreaCod = '';
			const selectedEmp = listCli.find(item => item.id === selectedCliId);
			if (selectedEmp) astreaCod = selectedEmp.astreaCod;
			//console.log('astreaCod  >>>>> ', astreaCod);
			if (!astreaCod) return;

			const q = query(
				collection(db, 'AgendaAstrea'),
				where('CodCliente', '==', astreaCod),
				orderBy('DataOrder', 'desc'),
				limit(10)
			);
			subscrib = onSnapshot(q, querySnapshot => {
				const data: ProcedureData[] = [];
				querySnapshot.forEach(doc => {
					//console.log(doc.data());
					const oItem: ProcedureData = {
						id: doc.id,
						Titulo: doc.data().Titulo || '',
						TituloProcesso: doc.data().TituloProcesso || '',
						CodCliente: doc.data().CodCliente || '',
						Data: doc.data().Data || '',
						Tipo: doc.data().Tipo || '',
						Responsavel: doc.data().Responsavel || '',
						Etiquetas: doc.data().Etiquetas || '',
						Juizo: doc.data().Juizo || '',
						NumeroProcesso: doc.data().NumeroProcesso || '',
						Observacoes: doc.data().Observacoes || '',
						DataOrder: doc.data().DataOrder
					};
					data.push(oItem);
				});
				setListProc(data);
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}

		setSearchParams({
			cli: selectedCli,
			id: selectedCliId
		});

		// console.log('selectedCli >>>> ', selectedCli);
		// console.log('selectedCliId >>>> ', selectedCliId);

		// cancel subscription to useEffect
		return () => {
			subscrib();
			// console.log('<<< [Document.List] Cancel Listner >>>');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, selectedCli]);
	console.log('teste', listProc.length > 0 && selectedCli);

	//-----------------------------------//-----------------------------------
	return (
		<S.Container>
			<SelectCustom
				externallabel="SELECIONE O VÍNCULO"
				id={selectedCliId}
				setId={setSelectedCliId}
				value={selectedCli}
				setValue={setSelectedCli}
				data={listCli}
			/>
			<>{isLoading}</>
			{listProc.length > 0
				? listProc.map(procedure => (
						<React.Fragment key={procedure.id}>
							<CardListItem
								id={procedure.id}
								title={procedure.Titulo}
								description={procedure.TituloProcesso}
								date={procedure.Data}
								code={procedure.Responsavel}
								status={procedure?.DataOrder <= today ? finishStatus : pendingStatus} // green status when actual or past date
								handleClick={function (item): void {
									const serializedItem = JSON.stringify(procedure);
									navigate(`/procedure_item/${item.id}`, { state: { item: serializedItem } });
								}}
							/>
						</React.Fragment>
				  ))
				: selectedCli && (
						<Box justifyContent={'center'} alignItems={'center'}>
							<Typography>Nenhum registro encontrado</Typography>
						</Box>
				  )}
		</S.Container>
	);
};
